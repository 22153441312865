$(document).ready(function () {
  function setPopoverData() {
    var $popoversLink = $('a[data-content]');
    if (!$popoversLink.length) {
      return;
    }

    var image = document.createElement('img');
    image.src = '/images/icon-close.png';

    $popoversLink.each(function(index, popoverLink) {
      image.onclick = (function(p) {
        return function() {
          $(p).popover('toggle');
        }
      })(popoverLink);

      $(popoverLink).popover({
        trigger: 'manual',
        html: 'true',
        title: image,
        placement: 'top'
      });

      $(popoverLink).on('click', function() {
        $(popoverLink).popover('toggle');
      })
    });
  }

  $(document).on('click', function (e) {
    $('[data-toggle="popover"],[data-original-title]').each(function () {
      //the 'is' for buttons that trigger popups
      //the 'has' for icons within a button that triggers a popup
      if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
        (($(this).popover('hide').data('bs.popover')||{}).inState||{}).click = false  // fix for BS 3.3.6
      }
    });
  }); 

  setPopoverData();
});
